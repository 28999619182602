import React from 'react'
import { i18nGet, locale } from './i18n'
import './PolicyPopup.css'

/**
 * Document source ./public/assets/Integritetsmeddelande intresseanmälan LOKATE.DOCX
 *
 * Converted Word to HTML here: https://wordtohtml.net
 *
 * Inline styles stripped here: https://html-cleaner.com
 * -----> In the left panel you have to go to Tools > Source Code and paste the HTML.
 * -----> Inline styles must be removed due to a security policy on the destination S3 server
 *
 * Converted HTML to JSX here: https://magic.reactjs.net/htmltojsx.htm
 *
 * Pasted into container, search file for "PASTEPOINT" to find location
 *
 */

const PolicyPopup = () => {
  const [showPrivacy, setShowPrivacy] = React.useState(false)
  return (
    <>
      <button
        type="button"
        className={
          'underline-offset-3 hover:underline-offset-8 transition duration-300 pointer underline'
        }
        onClick={() => setShowPrivacy(!showPrivacy)}
      >
        {i18nGet(locale, 'PrivacyPolicyBlurb').split(' ').reverse()[0]}
      </button>

      {showPrivacy ? (
        <>
          <div
            onClick={() => setShowPrivacy(false)}
            className="text-gray-800 not-italic font-poppins  justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Integritetsmeddelande intresseanmälan
                  </h3>
                  <div className="flex pl-5">
                    <div>
              {/* Website Logo */}
                    <a href="#" className="flex items-left">
                    <img
                  className="h-8 mt-6"
                  src="./assets/lokate-light-logo.svg"
                  alt="Logo"
                />
              </a>
            </div>
            {/* Primary Navbar items */}
            <div className="hidden md:flex items-center space-x-3"></div>
          </div>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowPrivacy(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* PASTEPOINT: paste body into classNamed div below */}
                <div className="relative p-6 flex-auto overflow-y-scroll h-200 max-h-80">
                  <div>
                    <p>
                      <strong>
                        {' '}
                        Integritetsmeddelande f&ouml;r intresseanm&auml;lan
                        f&ouml;r tj&auml;nsten LOKATE&nbsp;{' '}
                      </strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Detta meddelande inneh&aring;ller information om
                      Volvokoncernens behandling av dina&nbsp;
                    </p>
                    <p>
                      personuppgifter
                      (&rdquo;integritetsmeddelande&rdquo;).&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>
                        {' '}
                        Identitet och kontaktuppgifter f&ouml;r
                        personuppgiftsansvarig och dataskyddsombud{' '}
                      </strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Volvokoncernbolaget som du, f&ouml;r egen eller din
                      arbetsgivares r&auml;kning, anm&auml;lt ditt intresse
                      f&ouml;r tj&auml;nsten LOKATE (nedan kallad
                      &rdquo;Volvo&rdquo;) &auml;r personuppgiftsansvarig
                      f&ouml;r de personuppgifter som Volvo samlar in fr&aring;n
                      dig som beskrivs nedan.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      &rdquo;Personuppgiftsansvarig&rdquo; betyder att det
                      &auml;r Volvo som best&auml;mmer &auml;ndam&aring;let och
                      medlen f&ouml;r behandlingen av dina personuppgifter.
                      Volvo ansvarar f&ouml;r behandlingen av dina
                      personuppgifter enligt till&auml;mpliga dataskyddslagar
                      och f&ouml;rordningar.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Kontakta Volvokoncernens dataskyddsombud via
                      gpo.office@volvo.com om du har fr&aring;gor om
                      behandlingen av dina personuppgifter. Du kan &auml;ven
                      kontakta dataskyddsombudet via post eller telefon:&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      AB Volvo,&nbsp; <br /> &nbsp;Att: Group Privacy
                      Office,&nbsp; <br /> &nbsp;Dept AA14100, VGHQ&nbsp; <br />{' '}
                      &nbsp;405 08 G&ouml;teborg&nbsp; <br /> &nbsp;031-66 00 00
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>
                        {' '}
                        Vilka kategorier av personuppgifter kan Volvo komma att
                        behandla, p&aring; vilka r&auml;ttsliga grunder och
                        f&ouml;r vilka &auml;ndam&aring;l?{' '}
                      </strong>
                    </p>
                    <p>
                      <strong> &nbsp; </strong>
                    </p>
                    <p>
                      I detta Integritetsmeddelande avser termen
                      &ldquo;personuppgifter&rdquo; all information om en
                      individ eller som identifierar eller kan identifiera en
                      individ. Med andra ord &auml;r det vilken information som
                      helst som kan l&auml;nkas till dig.
                    </p>
                    <p>
                      I detta Integritetsmeddelande avser termen
                      &rdquo;behandla&rdquo; eller &rdquo;behandling&rdquo; all
                      anv&auml;ndning av personuppgifter, inklusive men inte
                      begr&auml;nsat till insamling, registrering, organisering,
                      lagring, bearbetning, &auml;ndring, &ouml;verf&ouml;ring,
                      tillg&auml;ngligg&ouml;rande, blockering, radering eller
                      f&ouml;rst&ouml;relse av personuppgifter.
                    </p>
                    <p>
                      <u> R&auml;ttslig grund </u>
                    </p>
                    <p>
                      Volvo kan komma att behandla dina personuppgifter baserat
                      p&aring; en eller flera av f&ouml;ljande r&auml;ttsliga
                      grunder, se &auml;ven ytterligare information nedan.
                    </p>
                    <ul type="disc">
                      <li>
                        Ber&auml;ttigade intressen. Volvo kan komma att behandla
                        dina personuppgifter om behandlingen kr&auml;vs f&ouml;r
                        att tillgodose ett ber&auml;ttigat intresse som Volvo
                        eller en tredje part har. Det anses allm&auml;nt ligga i
                        Volvos ber&auml;ttigade intresse att hantera den dagliga
                        verksamheten, inklusive att f&ouml;rb&auml;ttra och
                        vidareutveckla sitt utbud av produkter och tj&auml;nster
                        till sina kunder (antingen p&aring; egen hand eller
                        tillsammans med tredje parter) och inkluderande nya
                        innovativa produkter och tj&auml;nster.
                      </li>
                    </ul>
                    <p>
                      N&auml;r det h&auml;r anges att Volvo f&ouml;rlitar sig
                      p&aring; sina ber&auml;ttigade intressen f&ouml;r ett
                      visst bearbetnings&auml;ndam&aring;l, anser Volvo att dess
                      ber&auml;ttigade intressen inte &aring;sidos&auml;tts av
                      dina intressen, r&auml;ttigheter eller friheter med tanke
                      p&aring; (i) den &ouml;ppenhet som Volvo
                      tillhandah&aring;ller n&auml;r det g&auml;ller
                      behandlingsverksamheten, (ii) Volvos strategi f&ouml;r
                      inbyggd integritet, (iii) Volvos regelbundna
                      integritetgranskning och (iv) de r&auml;ttigheter du har i
                      f&ouml;rh&aring;llande till behandlingsverksamheten. Om du
                      vill f&aring; mer information om denna
                      intresseavv&auml;gning, kan du kontakta Volvokoncernens
                      dataskyddsombud via de kontaktuppgifter som anges ovan.
                    </p>
                    <ul type="disc">
                      <li>
                        R&auml;ttslig f&ouml;rpliktelse. Volvo kan komma att
                        behandla dina personuppgifter om det kr&auml;vs f&ouml;r
                        att uppfylla en r&auml;ttslig f&ouml;rpliktelse, t.ex.
                        f&ouml;r att f&ouml;lja domstolsbeslut och
                        r&auml;ttsliga rapporteringskrav.
                      </li>
                    </ul>
                    <p>
                      Volvo kan komma att behandla f&ouml;ljande kategorier av
                      uppgifter som i sig eller i kombination med andra
                      uppgifter kan utg&ouml;ra personuppgifter och f&ouml;r de
                      allm&auml;nna &auml;ndam&aring;l som anges i Tabell 1
                      nedan.
                    </p>
                    <p>
                      <em>
                        {' '}
                        Tabell 1 - Kategorier, &auml;ndam&aring;l och
                        r&auml;ttslig grund f&ouml;r behandling{' '}
                      </em>
                    </p>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <p>Kategorier av personuppgifter</p>
                          </td>
                          <td>
                            <p>&Auml;ndam&aring;l med behandling</p>
                          </td>
                          <td>
                            <p>R&auml;ttslig grund f&ouml;r behandling</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <ul>
                                <li>Namn</li>
                                <li>E-postadress</li>
                              </ul>
                            </div>
                          </td>
                          <td>
                            <div>
                              <ul>
                                <li>
                                  Administrera intresseanm&auml;lan f&ouml;r
                                  tj&auml;nsten i fr&aring;ga
                                </li>
                                <li>
                                  Marknadsf&ouml;ra tj&auml;nsten i fr&aring;ga
                                  t.ex. genomf&ouml;ra demo,
                                  tillhandah&aring;lla informationsmaterial
                                  eller nyhetsbrev.
                                </li>
                              </ul>
                            </div>
                          </td>
                          <td>
                            <div>
                              <ul>
                                <li>Ber&auml;ttigat intresse</li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>
                        {' '}
                        Varifr&aring;n samlar Volvo in dina personuppgifter?{' '}
                      </strong>
                    </p>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>
                    <p>
                      Volvo kommer att f&aring; dina personuppgifter fr&aring;n
                      dig sj&auml;lv genom din anm&auml;lan p&aring; Volvos
                      webbplats.
                    </p>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>
                    <p>
                      <strong>
                        {' '}
                        Vad h&auml;nder om du inte l&auml;mnar ut n&aring;gra
                        personuppgifter till Volvo?{' '}
                      </strong>
                    </p>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>
                    <p>
                      Om du inte l&auml;mnar personuppgifterna med Volvo
                      s&aring;&nbsp; hindrar det Volvo fr&aring;n att leverera
                      de produkter och tj&auml;nster som du eller din
                      arbetsgivare f&ouml;rv&auml;ntar er.
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>
                        {' '}
                        Hur delar Volvo dina personuppgifter och kommer dina
                        personuppgifter att delas utanf&ouml;r EU/EES??{' '}
                      </strong>
                    </p>
                    <p>
                      Dina personuppgifter kan delas med andra f&ouml;retag inom
                      Volvokoncernen och med vissa kategorier av tredje parter
                      (som beskrivs n&auml;rmare nedan), vilket kan
                      inneb&auml;ra att dina personuppgifter &ouml;verf&ouml;rs
                      till andra l&auml;nder.
                    </p>
                    <p>
                      <u> Delning av personuppgifter inom Volvokoncernen </u>
                    </p>
                    <p>
                      Volvokoncernen &auml;r en global organisation med kontor
                      och verksamhet &ouml;ver hela v&auml;rlden, och dina
                      personuppgifter kan &ouml;verf&ouml;ras eller vara
                      tillg&auml;ngliga internationellt inom hela
                      Volvokoncernens globala verksamhet och mellan dess olika
                      enheter och dotterbolag.&nbsp;
                    </p>
                    <p>
                      Alla &ouml;verf&ouml;ringar av dina personuppgifter till
                      andra f&ouml;retag i Volvokoncernen (inklusive
                      &ouml;verf&ouml;ringar fr&aring;n EU/EES till l&auml;nder
                      utanf&ouml;r EU/EES) regleras av ett
                      koncern&ouml;vergripande avtal baserat p&aring;
                      EU-godk&auml;nda standardavtalsklausuler eller andra
                      mekanismer som har erk&auml;nts eller godk&auml;nts av de
                      relevanta myndigheterna fr&aring;n tid till annan.
                      S&aring;dana avtal &aring;terspeglar de standarder som
                      finns i europeisk dataskyddslagstiftning (inklusive EU:s
                      allm&auml;nna dataskyddsf&ouml;rordning).&nbsp;
                    </p>
                    <p>
                      Att ha det h&auml;r avtalet inneb&auml;r att alla
                      Volvokoncernens enheter m&aring;ste f&ouml;lja samma
                      interna regler. Det betyder ocks&aring; att dina
                      r&auml;ttigheter &auml;r desamma oavsett var dina
                      uppgifter behandlas av Volvokoncernen.
                    </p>
                    <p>
                      <u>
                        {' '}
                        Delning av personuppgifter med tredje part utanf&ouml;r
                        Volvokoncernen{' '}
                      </u>
                    </p>
                    <p>
                      Ut&ouml;ver den delning av personuppgifter mellan bolag
                      inom Volvokoncernen som anges ovan kan Volvo ocks&aring;
                      dela dina personuppgifter med vissa kategorier av tredje
                      parter, inklusive:
                    </p>
                    <div>
                      <ul>
                        <li>
                          Aff&auml;rspartners &nbsp;s&aring; som Volvokoncernens
                          leverant&ouml;rer och tj&auml;nsteleverant&ouml;rer
                          som st&ouml;der Volvokoncernen i leveransen av
                          produkter och tj&auml;nster, inklusive
                          IT-leverant&ouml;rer och
                          tj&auml;nsteleverant&ouml;rer.
                        </li>
                      </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div>
                      <ul>
                        <li>
                          Professionella r&aring;dgivare, t.ex.
                          f&ouml;rs&auml;kringsgivare, advokater och andra
                          professionella r&aring;dgivare i samband med
                          f&ouml;rs&auml;kringsanspr&aring;k, revisioner och
                          mottagande av r&aring;dgivningstj&auml;nster.
                        </li>
                      </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div>
                      <ul>
                        <li>
                          Motparter och deras r&aring;dgivare, t.ex. i samband
                          med fusions- och f&ouml;rv&auml;rvsprojekt.
                        </li>
                      </ul>
                    </div>
                    <p>&nbsp;</p>
                    <div>
                      <ul>
                        <li>
                          R&auml;ttsv&auml;sende, tillsynsmyndigheter och andra
                          offentliga och r&auml;ttsliga organ i samband med
                          r&auml;ttsliga f&ouml;rpliktelser, t.ex.
                          domstolsbeslut eller lagstadgade rapporteringskrav,
                          eller om det i undantagsfall anses
                          n&ouml;dv&auml;ndigt f&ouml;r att skydda dina eller
                          andras grundl&auml;ggande intressen.
                        </li>
                      </ul>
                    </div>
                    <p>
                      Alla tredjepartsleverant&ouml;rer och professionella
                      r&aring;dgivare till vilka dina personuppgifter
                      l&auml;mnas ut f&ouml;rv&auml;ntas och &auml;r skyldiga
                      att skydda sekretessen och s&auml;kerheten f&ouml;r dina
                      personuppgifter och f&aring;r endast anv&auml;nda dina
                      personuppgifter i enlighet med till&auml;mpliga
                      dataskyddslagar och -f&ouml;rordningar.
                    </p>
                    <p>
                      Om n&aring;got av Volvokoncernens f&ouml;retag som &auml;r
                      bel&auml;get inom EU/EES &ouml;verf&ouml;r personuppgifter
                      till externa tredje parter som &auml;r bel&auml;gna
                      utanf&ouml;r EU/EES, kommer det relevanta f&ouml;retaget i
                      Volvokoncernen att f&ouml;rs&auml;kra sig om att det finns
                      l&auml;mpliga skydds&aring;tg&auml;rder som ger en adekvat
                      skyddsniv&aring; f&ouml;r dina personuppgifter i enlighet
                      med till&auml;mplig dataskyddslagstiftning (inklusive EU:s
                      allm&auml;nna dataskyddsf&ouml;rordning). Detta kan t.ex.
                      inbegripa anv&auml;ndning av EU-godk&auml;nda
                      standardavtalsklausuler eller andra mekanismer som har
                      erk&auml;nts eller godk&auml;nts av de relevanta
                      myndigheterna fr&aring;n tid till annan.
                    </p>
                    <p>
                      Om du har fr&aring;gor om hur Volvo kommer att dela dina
                      personuppgifter kan du kontakta Volvokoncernens
                      dataskyddsombud via de kontaktuppgifter som anges ovan.
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>
                        {' '}
                        Hur l&auml;nge sparar Volvo dina personuppgifter?{' '}
                      </strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Volvo lagrar dina personuppgifter s&aring; l&auml;nge det
                      finns ett ber&auml;ttigat aff&auml;rssyfte eller tills
                      dess du avanm&auml;ler ditt intresse.
                    </p>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>
                    <p>
                      <strong>Dina dataskyddsr&auml;ttigheter</strong>
                    </p>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>
                    <p>
                      Du har r&auml;tt att fr&aring;n Volvo beg&auml;ra
                      tillg&aring;ng till, r&auml;ttelse eller radering av dina
                      personuppgifter, begr&auml;nsning mot behandlingen av dina
                      personuppgifter eller att inv&auml;nda mot behandlingen
                      liksom att beg&auml;ra dataportabilitet av dina
                      personuppgifter. Observera att Volvo inte alltid &auml;r
                      skyldigt att uppfylla en beg&auml;ran om radering,
                      begr&auml;nsning, inv&auml;ndning eller dataportabilitet.
                      Bed&ouml;mning kan g&ouml;ras fr&aring;n fall till fall av
                      Volvos r&auml;ttsliga skyldigheter och undantaget
                      fr&aring;n s&aring;dana r&auml;ttigheter. Du har &auml;ven
                      r&auml;tt att framf&ouml;ra de klagom&aring;l du kan
                      t&auml;nkas ha ang&aring;ende Volvos behandling av dina
                      personuppgifter till en tillsynsmyndighet. F&ouml;r mer
                      information om dessa r&auml;ttigheter och hur du
                      ut&ouml;var dem kan du kontakta Volvokoncernens
                      dataskyddsombud via de kontaktuppgifter som anges ovan.
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-purple-600 font-poppins background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowPrivacy(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}

export default PolicyPopup
